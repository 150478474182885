var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialogs.showTransactions,"width":"1226","overlay-opacity":0.9,"scrollable":""},on:{"click:outside":_vm.closeDialog,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog.apply(null, arguments)}}},[_c('v-card',[_c('v-toolbar',{staticClass:"pr-0",attrs:{"height":48,"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Транзакции "+_vm._s(_vm.users.forDialog.user.username)+" ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"id":"affiliateMainTable","items":_vm.userTransactions,"headers":_vm.cols,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.moment.parseZone(item.created_at).local().locale('ru').format("DD MMM YYYY HH:mm"))+" ")])]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formattedReasons(item.reason))+" ")])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.comment)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency ? _vm.showSum(item) : item.sum)+" "),(item.provider === 'cloudpayments' && _vm.canCancelSelected(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary","icon":"","loading":_vm.loadingCancelButton,"disabled":_vm.loadingCancelButton},on:{"click":function($event){return _vm.cancelSelected(item)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Отменить")])]):_vm._e()]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"grey"},on:{"click":_vm.closeDialog}},[_vm._v(" Закрыть ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }